import { generateQueryParams } from 'utils';
import CommonService from './common.service';

class TaskService extends CommonService {
	async tasks(params: object) {
		return await this.get(`tasks?${generateQueryParams(params)}`)
	}

	async projects(params: object, startDate: any, endDate: any) {
		// console.log('startDate', startDate, 'startDate', startDate)

		return await this.get(`tasks-completed?project_id=${params}&start_date=${startDate}&end_date=${endDate}`)
	}

	async tasksReport() {
		return await this.get('tasks/report')
	}
}

export const taskService = new TaskService();