import React from 'react'

import { useSelector } from 'react-redux';
import moment from 'moment';
import { faCubesStacked, faCalendarDays } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getDueDateColorClass } from 'utils';
const TaskBox = (props: any) => {
	const item = props.item

	const { projects } = useSelector((state: any) => state.session);

	const getProjectNameById = (id: any) => {
		return projects.length > 0 && projects?.filter((item: any) => item.project_id == id)[0].project_name.substring(0, 30)
	}

	return (
		<>
			<li className={`mb-2 p-2 bg-light rounded-3 task-box task-project-${item.project_id}`}>
				<div className="to-do-list">
					<p className="mb-0">
						<a className="text-decoration-none text-dark" href={`https://freedcamp.com/view/${item.project_id}/tasks/panel/task/${item.id}`} target="_blank" rel="noreferrer">{item?.name}</a>

						{item.task_group_name !== 'Task List' ? <small className="d-block">{item.task_group_name}</small> : ''}
					</p>
					<hr className="my-2" />

					<div className="d-flex align-items-center">
						<p className="d-flex align-items-center me-3 mb-0">
							<span
								className="rounded-circle bg-primary text-white d-flex align-items-center justify-content-center e- avtar-logo"
								style={{ height: '20px', width: '20px' }}
							>
								{props?.user &&
									props?.user.split(' ')[0]?.charAt(0) +
									(props?.user.split(' ')[1]?.charAt(0) || '')}
							</span>
							&nbsp;
							<span className="to-do-sub-heading">{props.user}</span>
						</p>

						<p className="d-flex align-items-center me-3 due-date-card mb-0">
							<span>
								<FontAwesomeIcon icon={faCalendarDays} className={`${getDueDateColorClass(item.due_ts)}`} />
							</span>
							&nbsp;
							<span className={`to-do-sub-heading ${getDueDateColorClass(item.due_ts)}`}>
								{item?.due_ts ? moment.unix(item?.due_ts).format('MMM DD') : 'NA'}
							</span>
						</p>

						<p className="d-flex align-items-center mb-0">
							<span >
								<FontAwesomeIcon icon={faCubesStacked} />
							</span>&nbsp;
							<span className="to-do-sub-heading">
								<a href={`https://freedcamp.com/view/${item.project_id}/tasks`} target="_blank" rel="noreferrer">{getProjectNameById(item.project_id)}</a>
							</span>
						</p>
					</div>
				</div>
			</li>
		</>
	)
}
export default TaskBox