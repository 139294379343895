import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom'

import * as Yup from 'yup'
import { useFormik } from 'formik';

import { routes } from 'utils';
import { _Object } from 'utils/interfaces';
import { authService } from 'services';

import { Button, InputField, LinkButton } from 'views/components'

const ResetPassword = () => {
	// const navigate = useNavigate()

	const query = new URLSearchParams(location.search)
	const token = query.get('token')

	const [loading, setLoading] = useState(false)

	const formik = useFormik({
		initialValues: {
			new_password: '',
			confirm_password: '',
			token: token
		},
		enableReinitialize: true,

		validationSchema: Yup.object({
			new_password: Yup.string().required(),
			confirm_password: Yup.string().required()
		}),

		onSubmit: async (values) => {
			setLoading(true)
			authService.updatePassword(values)
				.then((data: _Object) => {
					data.error === false
					// && navigate(`${routes.login}`)
					setLoading(false)
					formik.resetForm();
				})
		}
	})

	return (

		<>
			<div className="col-8 mx-auto vh-100 pos-justify pos-align pos-center flex-column login login-form-vh">
				<div className="card border-0 position-relative">

					<div className="text-center">
						<img src="/assets/images/logo.svg" alt="" className="img-fluid w-50 mb-4" />
						<h4>Reset password</h4>
						<p>Type and confirm a secure new password for your account.</p>
					</div>
					<form onSubmit={formik.handleSubmit}>
						<InputField
							type="password"
							name="new_password"
							label="New password"
							required={true}
							value={formik.values.new_password}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
						/>

						<InputField
							type="password"
							name="confirm_password"
							label="Confirm password"
							required={true}
							value={formik.values.confirm_password}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
						/>

						<div className="pos-justify pos-between">
							<Button
								type="submit"
								name="Reset password"
								className="primary"
								loading={loading}
							/>
							<LinkButton
								label={'Sign in'}
								path={`${routes.login}`}
								className="link px-0"
							/>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default ResetPassword