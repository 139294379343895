export const routes = {
	login: '/login',
	forgotPassword: '/forgot-password',
	resetPassword: '/reset-password',
	dashboard: '/app/home',
	tasks: '/app/tasks',
	projects: '/app/projects',
	calendar: '/app/calendar',
	monthlytasks: '/app/monthly-tasks'
}

