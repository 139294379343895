import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { AppDispatch } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';

import { setDestroyAuth, setLoggedInUser, setProjects } from 'redux/slices/session.slice';

import Avatar from '../avatar';
import store from 'store';
import { projectService } from 'services';

const Header = () => {
	const location = useLocation();

	const { loggedInUser } = useSelector((state: any) => state.session);

	const dispatch = useDispatch<AppDispatch>()

	useEffect(() => {
		const token = store.get(`${process.env.REACT_APP_ACCESS_TOKEN_KEY}`)
		if (token?.length > 0) {
			dispatch(setLoggedInUser())
		}
	}, [])

	const handleLogout = () => {
		dispatch(setDestroyAuth());
	};
	useEffect(() => {
		projectService.project().then((data: any) => {
			dispatch(setProjects(data.items));
		})
	}, [])

	return (
		<header>
			<div className="container-fluid">
				<div className="row align-items-center">
					<div className="col-2">
						<a href="/app">
							<img src="/assets/images/logo.svg" height={60} width={200} alt="PieCodes" />
						</a>
					</div>

					<div className="col-6">
						<ul className="nav justify-content-center">
							<li className={`nav-item ${location.pathname === '/app/home' ? 'active' : ''}`}>
								<Link className="nav-link" to="/app/home">Home</Link>
							</li>
							<li className={`nav-item ${location.pathname === '/app/tasks' ? 'active' : ''}`}>
								<Link className="nav-link" to="/app/tasks">Tasks</Link>
							</li>
							<li className={`nav-item ${location.pathname === '/app/calendar' ? 'active' : ''}`}>
								<Link className="nav-link" to="/app/calendar">Calendar</Link>
							</li>
							<li className={`nav-item ${location.pathname === '/app/monthly-tasks' ? 'active' : ''}`}>
								<Link className="nav-link" to="/app/monthly-tasks">Monthly Tasks</Link>
							</li>
						</ul>
					</div>

					<div className="col-4 d-flex justify-content-end">
						<div className="dropdown-center">
							<button className="btn border-0 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
								<Avatar data={loggedInUser?.loggedInUser?.name} />
							</button>
							<ul className="dropdown-menu">
								<li><button className="dropdown-item" onClick={handleLogout}>Logout</button></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}

export default Header