import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import App from './App';
import store from './redux/store';

import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/app.scss'
import './assets/scss/main.scss'

import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<Provider store={store}>
		<App />
		<ToastContainer
			toastStyle={{ backgroundColor: 'blue', color: '#ffff' }}
			autoClose={2000}
			pauseOnHover
		/>

	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
