import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { taskService, projectService } from 'services';
import { setProjects } from 'redux/slices/session.slice';
import TaskBox from 'views/components/task_box';

const Tasks = () => {
	const dispatch = useDispatch()

	const [list, setList] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		taskService.tasksReport().then((data: object) => {
			setList(data);
			setLoading(false);
		});

		projectService.project().then((data: any) => {
			dispatch(setProjects(data.items))
		});
	}, []);

	return (
		<section className="to-do-list-section">
			<div className="container-fluid">
				<div className="position-sticky top-0 z-1 py-2" style={{ minHeight: '94px' }}>

					<div className="row align-items-center my-4">
						<div className="col">
							<h2 className="my-auto">Consolidated tasks</h2>
						</div>

						{/* <div className="col d-flex justify-content-end">
							<div className="d-flex gap-2">
								<div className="dropdown-center">
									<SelectField
										options={[
											{ value: 'tasks', label: 'Tasks' },
											{ value: 'projects', label: 'Projects' }
										]}
									/>
								</div>
							</div>
						</div> */}
					</div>
				</div>

				<div className="row flex-nowrap overflow-auto">
					{list?.items?.length > 0 ?
						list?.items?.map((item: any) => {
							return (
								<div className="col" key={item.id}>
									<div className="card">
										<div className="card-header">{item.user.name}</div>

										<div className={`card-body overflow-auto consolidated-tasks ${item.tasks.to_do.length > 0 && item.tasks.in_progress.length > 0 ? 'both' : ''}`} style={{ paddingBottom: 0, width: item.tasks.to_do.length > 0 && item.tasks.in_progress.length > 0 ? '700px' : '350px' }}>
											{
												item.tasks.to_do.length > 0 &&
												<div className="consolidated-tasks-list" style={{ float: 'left' }}>
													<h6>To Do&nbsp;<small>({item.tasks.to_do.length})</small></h6>

													<ul className="list-unstyled p-0">
														{
															item.tasks.to_do.map((item1: any, i: number) => {
																return (
																	<>
																		<TaskBox item={item1} user={item.user.name} key={`${item1.id}-${i}`} />
																	</>
																)
															})
														}
													</ul>
												</div>
											}
											{
												item.tasks.in_progress.length > 0 &&
												<div className="consolidated-tasks-list" style={{ float: 'right' }}>
													<h6>In Progress&nbsp;<small>({item.tasks.in_progress.length})</small></h6>

													<ul className="list-unstyled p-0">
														{item.tasks.in_progress.map((item1: any, i: number) => {
															return (
																<TaskBox item={item1} user={item.user.name} key={`${item1.id}-${i}`} />
															)
														})}
													</ul>
												</div>
											}
										</div>

									</div>
								</div>
							)
						})
						:

						<div className="d-inline-block" style={{ width: 350 }}>
							<div className="card" style={{ width: '100%' }}>
								<div className="card-header placeholder-glow">
									<span className="placeholder w-25"></span>
								</div>

								<div className="card-body overflow-auto" style={{ paddingBottom: 0 }}>
									<ul className="list-unstyled p-0">

										<li className="mb-2 p-2 bg-light rounded-3">

											<p className="placeholder-glow mb-0">
												<span className="placeholder col-12">
												</span>
											</p>
											<hr className="my-2" />
											<p className="placeholder-glow mb-0">
												<span className="placeholder col-12"></span>
											</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
					}

				</div>
			</div>
		</section >
	);
};

export default Tasks;
