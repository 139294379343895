import moment from 'moment'
import { routes } from './routes'
import { strings } from './strings'
export { routes, strings }

export const generateQueryParams = (args: any) => {
	let str = ''
	let replace = ''
	for (const key in args) {
		if (typeof (args[key]) === 'object') {
			for (const key1 in args[key]) {
				str += `&filters[${key1}]=${args[key][key1]}`
				replace = str.slice(1)
			}
		} else {
			str += `&${key}=${args[key]}`
			replace = str.slice(1)
		}
	}
	return replace
}

export const capitalizeFirstLetter = (name = '') => {
	const firstLetter = name?.charAt(0).toUpperCase()
	const restOfName = name?.slice(1).toLowerCase()
	const capitalizedUserName = firstLetter + restOfName
	return capitalizedUserName;
};

export const getDueDateColorClass = (due_ts: number) => {
	const dueDate = due_ts ? new Date(due_ts * 1000) : null
	const currentDate = new Date()
	let color = 'black'

	if (dueDate) {
		const timeDifference = dueDate.getTime() - currentDate.getTime()
		const daysDifference = timeDifference / (1000 * 3600 * 24)

		if (daysDifference > 3) {
			color = 'text-dark'
		} else if (daysDifference < 3 && daysDifference > 0) {
			color = 'text-warning'
		} else if (daysDifference <= 0) {
			color = 'text-danger'
		}
	}
	return color
}

export function convertUnixToMonthDay(timestamp: any) {
	if (!timestamp) return ''
	const date = moment.unix(timestamp);
	const monthDay = date.format('MMM DD');
	const year = date.format('YYYY');
	return `${monthDay}, ${year}`;
}

export function getStartAndEndDates(monthName: any) {
	const startDate = moment(`${monthName} 1 ${2024}`, 'MMMM D YYYY')
	const endDate = startDate.clone().endOf('month')
	return {
		startDate: startDate.format('YYYY-MM-DD'),
		endDate: endDate.format('YYYY-MM-DD')
	}
}