import React, { useEffect, useState } from 'react';
import { _Object } from 'utils/interfaces';

const OTPField = ({ args }: _Object) => {
	const [otp, setOTP] = useState<_Object>({})

	useEffect(() => {
		(Object.values(otp).join('')).length === args.length && args.onChange(Object.values(otp).join(''))
	}, [(Object.values(otp).join('')).length, otp])

	useEffect(() => {
		args.resend && setOTP({ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' })
	}, [args.resend])

	const handleChange = (event: _Object) => {
		const { name, value } = event.target;
		setOTP((prev: _Object) => ({ ...prev, [name]: value }));

		if (value !== '') {
			const nextFieldIndex = parseInt(name) + 2;
			const nextField = event.target.form.elements[nextFieldIndex] as HTMLInputElement;
			if (nextField) {
				nextField.focus();
			}
		}
	};

	const inputFocus = (element: _Object) => {
		if (element.key === 'Delete' || element.key === 'Backspace') {
			const next = element.target.tabIndex - 1
			if (next > -1) {
				element.target.form.elements[next].focus()
			}
		}
		else {
			const next = element.target.tabIndex
			if (next < 6) {
				element.target.form.elements[element.key === 'v' ? 5 : next].focus()
			}
		}
	}

	const handlePaste = (event: any) => {
		const value = event.clipboardData.getData('text')
		value.split('').map((item: any, i: number) => {
			return (
				setOTP((pre: any) => ({
					...pre,
					[i]: item
				}))
			)
		})
	}

	return (
		<div className="security-code">
			{Array.from({ length: args?.length ? args?.length : 4 }).map((item, i: number) => {
				return (
					<input
						key={i}
						type={args?.secure ? 'password' : 'text'}
						name={`${i}`}
						tabIndex={i + 1}
						maxLength={1}
						value={otp[i]}
						className="form-control"
						onChange={e => handleChange(e)}
						onKeyUp={e => inputFocus(e)}
						onPaste={e => handlePaste(e)}
						autoFocus={i === 0}
						id="opt-field"
					/>
				)
			})}
		</div>
	)
}

export default OTPField
