import React from 'react'

import { useSelector } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCubesStacked, faCalendarDays } from '@fortawesome/free-solid-svg-icons'
import { getDueDateColorClass } from 'utils';

const ListItems = (props: any) => {
	const { projects } = useSelector((state: any) => state.session)

	const getProjectNameById = (id: any) => {
		return projects.length > 0 && projects?.filter((item: any) => item.project_id == id)[0].project_name.substring(0, 19)
	}

	return (
		<>

			{props?.items?.length ?
				<div className="d-inline-block" style={{ width: 350 }}>
					<div className="card" style={{ width: '100%' }}>
						<div className="card-header">{props.title}&nbsp;<small>({props.items ? props.items.length : ''})</small></div>

						<div className="card-body overflow-auto" style={{ paddingBottom: 0 }}>
							<ul className="list-unstyled p-0">
								{props.items && props.items.map((item: any, i: number) => {
									return (
										<li key={`${item.id}-${i}`} className={`mb-2 p-2 bg-light rounded-3 task-box task-project-${item.project_id}`}>
											<div className="to-do-list">
												<p className="mb-0">
													<a className="text-decoration-none text-dark" href={`https://freedcamp.com/view/${item.project_id}/tasks/panel/task/${item.id}`} target="_blank" rel="noreferrer">
														{item?.title}
													</a>

													{item.task_group_name !== 'Task List' ? <small className="d-block">{item.task_group_name}</small> : ''}
												</p>
												<hr className="my-2" />
												<div className="d-flex align-items-center">
													<p className="d-flex align-items-center me-3 mb-0">
														<span
															className="rounded-circle bg-primary text-white d-flex align-items-center justify-content-center e- avtar-logo"
															style={{ height: '20px', width: '20px' }}
														>
															{item?.assigned_to_fullname &&
																item?.assigned_to_fullname.split(' ')[0]?.charAt(0) +
																(item?.assigned_to_fullname.split(' ')[1]?.charAt(0) || '')}
														</span>
														&nbsp;
														<span className="to-do-sub-heading">{item?.assigned_to_fullname ? item?.assigned_to_fullname : 'Not assigned'}</span>
													</p>

													<p className="d-flex align-items-center me-3 due-date-card mb-0">
														<span>
															<FontAwesomeIcon icon={faCalendarDays} className={`${getDueDateColorClass(item.due_ts)}`} />
														</span>
														&nbsp;
														<span className={`to-do-sub-heading ${getDueDateColorClass(item.due_ts)}`}>
															{item?.due_ts ? moment.unix(item?.due_ts).format('MMM DD') : 'NA'}
														</span>
													</p>

													<p className="d-flex align-items-center mb-0">
														<span>
															<FontAwesomeIcon icon={faCubesStacked} />
														</span>
														&nbsp;
														<span className="to-do-sub-heading">
															<a href={`https://freedcamp.com/view/${item.project_id}/tasks`} target="_blank" rel="noreferrer">{getProjectNameById(item.project_id)}</a>
														</span>
													</p>
												</div>
											</div>
										</li>
									)
								})}
							</ul>
						</div>
					</div>
				</div>
				:
				props?.isSkeleton &&
				<div className="d-inline-block" style={{ width: 350 }}>
					<div className="card" style={{ width: '100%' }}>
						<div className="card-header placeholder-glow">
							<span className="placeholder w-25"></span>
						</div>

						<div className="card-body overflow-auto" style={{ paddingBottom: 0 }}>
							<ul className="list-unstyled p-0">

								<li className="mb-2 p-2 bg-light rounded-3">

									<p className="placeholder-glow mb-0">
										<span className="placeholder col-12">
										</span>
									</p>
									<hr className="my-2" />
									<p className="placeholder-glow mb-0">
										<span className="placeholder col-12"></span>
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>

			}

		</>
	)
}
export default ListItems