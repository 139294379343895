import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { tagService, taskService } from 'services';
import { SelectField } from 'views/components';
import ListItems from 'views/components/list-items';

const Home = () => {
	const [list, setList] = useState<any>([]);
	const [defaultList, setDefaultList] = useState<any>([]);
	const [page, setPage] = useState<any>(1);
	const [loading, setLoading] = useState(true);
	const [filterdItem, setFilterdItem] = useState<any>({});

	const { projects } = useSelector((state: any) => state.session);

	// useEffect(() => {
	// 	projectService.project().then((data: any) => {
	// 		dispatch(setProjects(data.items));
	// 	})
	// }, [])

	useEffect(() => {
		taskService.tasks({ page: page }).then((data: any) => {
			setLoading(false);
			if (data?.items && data?.items?.length > 0) {
				setDefaultList((prevList: any) => ({
					...prevList,
					items: [...(prevList.items || []), ...(data.items || [])]
				}))
				setList((prevList: any) => ({
					...prevList,
					items: [...(prevList.items || []), ...(data.items || [])]
				}))

				setTimeout(() => {
					LoadMore();
				}, 400);
			}
		});
	}, [page])

	const LoadMore = () => {
		setLoading(true);
		setPage((prevPage: number) => prevPage + 1)
	};

	const projectFilter = (list1: any, status: string, tags1?: string[], tags2?: string[]) => {
		if (!filterdItem || Object.entries(filterdItem).length === 0) {
			return defaultList?.items?.filter((item: any) => {
				if (item?.status_title === status) {
					if (tags2) {
						for (const tag2 of tags2) {
							if (item?.tags?.includes(tag2))
								return false
						}
					}

					if (tags1) {
						for (const tag1 of tags1) {
							if (!item?.tags?.includes(tag1))
								return false
						}
					}
					return !filterdItem?.value || item?.project_id === filterdItem?.value
				}
				return false;
			})
		}

		return list1?.items?.filter((item: any) => {
			if (item?.status_title === status) {
				if (tags2) {
					for (const tag2 of tags2) {
						if (item?.tags?.includes(tag2))
							return false
					}
				}

				if (tags1) {
					for (const tag1 of tags1) {
						if (!item?.tags?.includes(tag1))
							return false
					}
				}

				return !filterdItem?.value || item?.project_id === filterdItem?.value;
			}
			return false;
		})
	}

	const groupFilter = (e: any) => {
		if (e?.value?.length > 0) {
			const finalData = list?.items?.filter((data: any) => data?.task_group_name === e?.value)
			setList({ items: finalData })
		} else {
			setList(defaultList)
		}
	}

	const uniqueArray = list?.items?.filter((item: any) => item?.project_id === filterdItem?.value)?.map((item: any) => item)?.reduce((accumulator: any, current: any) => {
		const existingItem = accumulator?.find((item: any) => item?.task_group_name === current?.task_group_name)

		if (!existingItem) {
			accumulator.push(current);
		}
		return accumulator;
	}, [])

	return (
		<>
			<section className="to-do-list-section">
				<div className="container-fluid">
					<div className="position-sticky top-0 z-1 py-2">
						<div className="row align-items-center my-2">
							<div className="col">
								<h2 className="my-auto">Task list</h2>
							</div>

							{uniqueArray?.length > 0 && !(uniqueArray[0]?.task_group_name === 'Task List') &&
								<div className="col-3">
									<SelectField
										className="sort-field"
										placeholder="Group name"
										label="Group name"
										name="sorting"
										options={uniqueArray?.map((item: any) => ({
											label: item?.task_group_name,
											value: item?.task_group_name
										}))}
										isSearchable={true}
										isClearable
										onChange={(e: any) => groupFilter(e)}
										value={{ value: filterdItem?.label }}
									/>
								</div>
							}

							<div className="dropdown-center col-3 mb-3">
								<SelectField
									className="sort-field"
									placeholder="Choose project"
									label="Filter by project"
									name="sorting"
									options={projects?.map((item: any) => ({
										label: item?.project_name,
										value: item?.project_id
									}))}
									onChange={(e: any) => { setFilterdItem(e), setList(defaultList) }}
									value={{ value: filterdItem?.label }}
									isSearchable={true}
									isClearable
									id="abc"
								/>
							</div>
						</div>
					</div>

					<div className="row flex-nowrap overflow-auto">
						<ListItems title={'To Do'} items={projectFilter(list, 'No Progress')} isSkeleton={loading} />
						<ListItems title={'In Progress'} items={projectFilter(list, 'In Progress')} isSkeleton={loading} />
						<ListItems title={'QA Ready'} items={projectFilter(list, 'Completed', [], ['119048', '121520', '118977', '126340', '127757'])} isSkeleton={loading} />
						<ListItems title={'Re Assessment Needed'} items={projectFilter(list, 'Completed', ['127757'])} isSkeleton={loading} />
						<ListItems title={'QA Passed - Dev'} items={projectFilter(list, 'Completed', ['118977'])} isSkeleton={loading} />
						<ListItems title={'QA Passed - Production'} items={projectFilter(list, 'Completed', ['126340'])} isSkeleton={false} />
						<ListItems title={'On Hold'} items={projectFilter(list, 'Completed', ['121520'])} isSkeleton={false} />
						<ListItems title={'Not Applicable'} items={projectFilter(list, 'Completed', ['119048'])} isSkeleton={false} />
					</div>
				</div>
			</section>
		</>
	);
};

export default Home;
