import store from 'store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { authService } from 'services';

const initialState = {
	isUserLoggedIn: store.get(`${process.env.REACT_APP_ACCESS_TOKEN_KEY}`) ? true : false,
	me: {},
	loggedInUser: {},
	globalSettings: {},
	printerDevice: '',
	projects: []
}

export const setLoggedInUser = createAsyncThunk('setLoggedInUser', async () => {
	const loggedInUser = await authService.getMe()

	return { loggedInUser }
})

const sessionSlice = createSlice({
	name: 'session',
	initialState: initialState,
	reducers: {
		setAuth: (state, action) => {
			store.set(process.env.REACT_APP_ACCESS_TOKEN_KEY || 'bIESIcg42Wb9', action.payload.access_token)
			state.isUserLoggedIn = true
		},
		setPrintDevice: (state, action) => {
			state.printerDevice = action.payload
		},
		setDestroyAuth: (state) => {
			store.clearAll()
			state.isUserLoggedIn = false
		},
		setProjects: (state, action) => {
			state.projects = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(setLoggedInUser.fulfilled, (state, action) => {
				state.loggedInUser = action.payload
			})
	}
});

export const { setAuth, setDestroyAuth, setPrintDevice, setProjects } = sessionSlice.actions;

export default sessionSlice.reducer;

