import React, { useState } from 'react';

import * as Yup from 'yup'
import { useFormik } from 'formik';

import { _Object } from 'utils/interfaces';
import { authService } from 'services';
import { Button, InputField, LinkButton } from 'views/components';

const Forgot = () => {
	// const navigate = useNavigate()
	const [loading, setLoading] = useState(false)

	const formik = useFormik({
		initialValues: { email: '' },
		enableReinitialize: true,

		validationSchema: Yup.object().shape({
			email: Yup.string().required()
		}),

		onSubmit: async (values) => {
			setLoading(true)
			authService.forgotPassword(values).then((data: _Object) => {
				data.error === false
				// && navigate(`${routes.login}`)
				setLoading(false)
				formik.resetForm();
			})
		}
	})

	return (
		<>
			<div className="col-8 mx-auto vh-100 pos-justify pos-align pos-center flex-column login login-form-vh">
				<div className="card border-0 position-relative">
					<div className="text-center">
						<img src="/assets/images/logo.svg" alt="" className="img-fluid w-50 mb-4" />
						<h4>Reset Your Password</h4>
						<p>Type your email and we&#39;ll send you a link to reset your password</p>
					</div>
					<form className="row align-items-center"
						onSubmit={formik.handleSubmit}>
						<InputField
							className="col-9"
							type="email"
							name="email"
							label="Email"
							required={true}
							value={formik.values.email}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik?.touched.email && formik.errors.email}
						/>

						<div className="col-3">
							<Button
								type="submit"
								name="Send"
								className="primary w-100"
								loading={loading}
							/>
						</div>
					</form>

					<div className="text-center mt-4">
						<p className="mb-0 pos-justify pos-center align-items-baseline">
              Already have an account? <LinkButton label="Sign In" path="/login" className="link text-decoration-underline fw-normal ps-1 p-0" />
						</p>
					</div>
				</div>
			</div>
		</>
	)
}
export default Forgot