import React, { useEffect, useState } from 'react';
import { projectService } from 'services';

const Projects = () => {
	const [list, setList] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		projectService.project().then((data: object) => {
			setList(data);
			setLoading(false);
		});
	}, []);

	return (
		<>
			Project
		</>
	);
};

export default Projects;
