import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { taskService } from 'services';
import { convertUnixToMonthDay, getStartAndEndDates } from 'utils'
import { SelectField } from 'views/components'

const MonthlyTasks = () => {
	const [list, setList] = useState<any>([])
	const { projects } = useSelector((state: any) => state.session)
	const [loading, setLoading] = useState(false);
	const calendarMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
	const currentMonth = calendarMonths[new Date().getMonth()]
	const formik: any = useFormik({
		initialValues: {
			projectName: '',
			month: `${currentMonth}`
		},

		enableReinitialize: true,
		validationSchema: Yup.object({
			projectName: Yup.string().label('projectName').required()
		}),

		onSubmit: async (values) => {
			console.log('')
		}
	})

	useEffect(() => {
		if (formik?.values?.projectName !== '') {
			setLoading(true)
			const startEndDate = getStartAndEndDates(formik?.values?.month ? formik?.values?.month : '')

			taskService.projects(formik?.values?.projectName, startEndDate?.startDate, startEndDate?.endDate).then((data: any) => {
				setList(data)
				setLoading(false)
			})
		}
		else {
			setList({})

			setLoading(false)
		}
	}, [formik?.values])

	return (
		<>
			<div className="container-fluid">
				<div className="row">

					<form onSubmit={formik.handleSubmit} className="d-flex pt-3">
						<div className="col my-auto">
							<h2>Monthly Tasks</h2>
						</div>
						<div className="col-3 pe-3">
							<SelectField
								name="projectName"
								required={true}
								label="Filter by project"
								options={projects?.map((item: any) => ({
									label: item?.project_name,
									value: item?.project_id
								}))}
								placeholder="Choose project"
								value={{ value: formik?.values?.projectName }}
								onChange={(e: any) => {
									formik.setFieldValue('projectName', e?.value || '')
									// formik.setFieldValue('month', `${currentMonth}`)
								}}
								error={formik?.touched.projectName && formik.errors.projectName}
								isSearchable={true}
								isClearable
							/>
						</div>
						<div className="col-3">
							<SelectField
								name="month"
								required={false}
								label="Choose month"
								options={calendarMonths?.map((item: any) => ({
									label: item,
									value: item
								}))}
								placeholder="Choose month"
								value={{ value: formik?.values?.month ? formik?.values?.month : {} }}
								onChange={(e: any) => {
									formik.setFieldValue('month', e ? e.value : '')
								}}
								isSearchable={true}
								isClearable
								defaultValue={{ label: `${currentMonth}`, value: '' }}
							/>
						</div>
					</form>

					<div>
						<table className="table table-bordered align-middle">
							<thead>
								<tr>
									<th scope="col">#</th>
									<th scope="col">Task</th>
									<th scope="col">Date</th>
								</tr>
							</thead>
							<tbody>

								{(list?.items?.length === undefined || list?.items?.length === 0) && !loading &&
									<tr>
										<td scope="row" className="text-center" colSpan={8}>No record found</td>
									</tr>
								}

								{(list?.items?.length > 0 || list?.items?.length !== undefined) && !loading ? (
									list?.items?.map((item: any, i: number) => {
										return (
											<tr key={i}>
												<th scope="row">{i + 1}</th>
												<td>
													<a href={item?.url} target="_blank" rel="noopener noreferrer">
														{item?.title} <br />
													</a>
													<p className="mb-0" style={{ fontSize: '13px' }}>{item?.task_group_name}</p>
												</td>
												<td>{convertUnixToMonthDay(item?.completed_ts)}</td>
											</tr>
										)
									})
								) :
									(
										loading && (
											<tr>
												<td>
													<p className="card-text placeholder-glow">
														<p className="mb-0 placeholder col-12"></p>
													</p>
												</td>
												<td>
													<p className="card-text placeholder-glow">
														<p className="mb-0 placeholder col-12"></p>
													</p>
												</td>
												<td>
													<p className="card-text placeholder-glow">
														<p className="mb-0 placeholder col-12"></p>
													</p>
												</td>
											</tr>
										)
									)}
							</tbody>
						</table>
					</div>
				</div>
			</div	>
		</>
	)
}
export default MonthlyTasks