import store from 'store';
import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { toast } from 'react-toastify';
import { capitalizeFirstLetter } from 'utils';

export default class CommonService {
	axiosInstance: AxiosInstance;

	constructor() {
		this.axiosInstance = axios.create({
			baseURL: process.env.REACT_APP_BASE_URL
		})

		this.axiosInstance.interceptors.request.use((request: any) => {
			request.headers = {
				'Accept': 'application/json',
				'Content-Type': request.url === 'products/import-csv' ? 'multipart/form-data' : 'application/json',
				'Authorization': `${store.get(`${process.env.REACT_APP_ACCESS_TOKEN_KEY}`)}`
			}
			return request
		},
		(error) => {
			return Promise.reject(error);
		}
		)

		this.axiosInstance.interceptors.response.use(
			(results: AxiosResponse) => {
				toast.success(results.data.message, {
					autoClose: 3000
				})
				return results.data
			},

			(error: { [key: string]: AxiosResponse } | any) => {
				const message = typeof error?.response?.data?.message === 'string' ? error?.response?.data?.message : JSON.stringify(error?.response?.data?.message)
				toast.error(
					message
						? `${capitalizeFirstLetter(message)}`
						: `${capitalizeFirstLetter(error.message)}: /${error.config.url}`,
					{
						autoClose: 3000
					}
				);

				if (error?.response?.status === 401) {
					store.clearAll()
					window.location.href = '/login'
				}
				// if ([200, 400, 404].includes(error?.response?.status) === false) {
				//   setTimeout(() => {
				//     store.clearAll()
				//     window.location.replace('/login')
				//   }, 500);
				// }
				return error?.response?.data
			}
		)
	}

	async get(endpoint: string, params = '') {
		return await this.axiosInstance.get(endpoint + params)
	}

	async post(endpoint: string, params = {}) {
		return await this.axiosInstance.post(endpoint, params instanceof FormData ? params : JSON.stringify(params))
	}

	async put(endpoint: string, params = {}) {
		return await this.axiosInstance.put(endpoint, params && JSON.stringify(params))
	}

	async delete(endpoint: string) {
		return await this.axiosInstance.delete(endpoint)
	}
}