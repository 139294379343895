import CommonService from './common.service';

class TagService extends CommonService {
	async tag() {
		return await this.get('tags')
	}
}

export const tagService = new TagService();

