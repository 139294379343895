import CommonService from './common.service';

class ProjectService extends CommonService {
	async project() {
		return await this.get('projects')
	}
}

export const projectService = new ProjectService();

