import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import * as Yup from 'yup'
import { useFormik } from 'formik'

import { routes } from 'utils'

import { InputField, Button } from 'views/components/form-inputs'
import './login.scss'
import { authService } from 'services'
import { setAuth } from 'redux/slices/session.slice'
import { _Object } from 'utils/interfaces'
import { OTPField } from 'views/components'

const Login = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [loading, setLoading] = useState(false)
	const [resend, setResend] = useState(false)
	const [action, setAction] = useState<any>(false)

	const formik = useFormik({
		initialValues: {
			email: '',
			otp: ''
			// action: 'login'
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			email: Yup.string().label('Email').required().email(),
			otp: Yup.string()
		}),
		onSubmit: (values: any) => {
			setLoading(true)

			if (action === false) {
				// formik.setFieldValue('otp', '')
				delete values.otp
				authService.login(values).then((data: _Object) => {
					if (data.error === false) {
						// values.action = 'verify'
						setAction(true)
					}
					setLoading(false)
					setResend(false)
				})
			}
			if (action === true && resend === false) {
				authService.verify(values).then((data: _Object) => {
					if (data.error === false) {
						dispatch(setAuth(data))
						setLoading(false)
						navigate(`${routes.dashboard}`)
					}
					setLoading(false)
				})
			}
			if (resend === true) {
				authService.login({ email: values.email }).then((data: _Object) => {
					if (data.error === false) {
						setAction(true)
					}
					const focusId = document.getElementById('opt-field')
					if (focusId) {
						focusId.focus()
					}
					setLoading(false)
					setResend(false)
				})
			}
		}
	})

	return (
		<>
			<div className="col-8 mx-auto vh-100 pos-justify pos-align pos-center flex-column login login-form-vh">
				<div className="card border-0 position-relative">
					<div className="text-center mb-2">
						<img src="/assets/images/logo.svg" height={30} width={200} alt="logo" className="img-fluid w-50 mb-4" />
						<h5>Log in to continue</h5>
						{/* <p>Sign in to your account</p> */}
					</div>
					{action === false ? (
						<div>
							<form onSubmit={formik.handleSubmit}>
								<InputField
									type="email"
									name="email"
									placeholder="Enter your email"
									onChange={formik.handleChange}
									value={formik.values.email}
									error={formik?.touched.email && formik.errors.email}
									autoFocus
								/>

								<Button
									loading={loading}
									disabled={loading}
									className="primary w-100"
									label={'Continue'}
									type="submit"
								/>

								{/* <button type="button" className="btn btn-primary  w-100">Continue</button> */}

							</form>
						</div>
					) : (
						<div>
							<form onSubmit={formik.handleSubmit} autoComplete="off">
								<InputField
									type="email"
									name="email"
									placeholder="Enter your email"
									onChange={formik.handleChange}
									value={formik.values.email}
									error={formik?.touched.email && formik.errors.email}
									autoFocus
								/>
								<OTPField
									id="otpField"
									args={{
										length: 6,
										secure: false,
										onChange: (otp: string) => otp && formik.setFieldValue('otp', otp),
										resend: resend
									}}
								/>

								<Button
									loading={loading}
									disabled={loading}
									className="primary w-100"
									label={'VERIFY'}
									type="submit"
								/>

								<p className="resend mb-0">Didn&#39;t get the code?
									<button onClick={() => { formik.setFieldValue('action', 'resend'); setResend(true) }}
										className="text-capitalize text-primary bg-transparent border-0">Resend
									</button>
								</p>
							</form>
						</div>
					)}

					<div className="text-center mt-4 pt-4">

					</div>
				</div>
			</div>
		</>
	)
}

export default Login