import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { Loading } from 'views/components'
import Header from 'views/components/header'

const PrivateLayout = () => {
	return (
		<>
			<Suspense fallback={<Loading />}>
				<main>
					<Header />
					<Outlet />
				</main>
			</Suspense>
		</>
	)
}
export default PrivateLayout