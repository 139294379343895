import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { projectService, taskService } from 'services';
import { setProjects } from 'redux/slices/session.slice';
import moment from 'moment';
import { SelectField } from 'views/components';
import { useDispatch, useSelector } from 'react-redux';
import { getDueDateColorClass } from 'utils';

const Calendar = () => {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(true)
	const [list, setList] = useState<any>([])
	const [page, setPage] = useState<any>(1)
	const [filterItem, setFilterItem] = useState<any>({})

	const changeDateFormate = (timestamp: any) => {
		const date = moment.unix(timestamp);
		return date.format('YYYY-MM-DD')
	}

	const { projects } = useSelector((state: any) => state.session)

	const getProjectNameById = (id: any) => {
		return projects.length > 0 && projects?.filter((item: any) => item.project_id == id)[0].project_name.substring(0, 19)
	}

	useEffect(() => {
		projectService.project().then((data: any) => {
			dispatch(setProjects(data.items))
		});
	}, []);

	useEffect(() => {
		taskService.tasks({ page: page }).then((data: any) => {
			setLoading(false)
			if (data?.items && data?.items.length > 0) {
				setList((prevList: any) => ({
					...prevList,
					items: [...(prevList.items || []), ...(data.items || [])]
				}))

				setTimeout(() => {
					LoadMore()
				}, 400);
			}
		})
	}, [page]);

	const LoadMore = () => {
		setLoading(true)
		setPage((prevPage: number) => prevPage + 1)
	}

	const renderEventContent = (eventInfo: any) => {
		const data = JSON.parse(eventInfo.event.title)

		return (
			<>
				<span className="d-block"><a className={`${getDueDateColorClass(data?.due_ts)}`} href={`https://freedcamp.com/view/${data.project_id}/tasks/panel/task/${data.id}`} target="_blank" rel="noreferrer">{data.title}</a></span>
				<span className="d-block"><small className="text-dark">{data.assigned_to_fullname}</small></span>
				<span className="d-block"><small><a className="text-dark" href={`https://freedcamp.com/view/${data.project_id}/tasks`} target="_blank" rel="noreferrer">{data.project_name}</a></small></span >
				{data.task_group_name !== 'Task List' ? <small className="d-block text-dark">{data.task_group_name}</small> : ''}
			</>
		);
	}

	return (
		<>
			<section className="to-do-list-section">
				<div className="container-fluid">
					<div className="position-sticky top-0 z-1 py-2">
						<div className="row align-items-center my-2">
							<div className="col">
								<h2 className="my-auto">Task calendar</h2>
							</div>

							<div className="col-3">
								<div className="dropdown-center">
									<SelectField
										className="sort-field"
										placeholder="Choose project"
										label="Filter by project"
										name="sorting"
										options={
											projects && projects?.map((item: any) => ({
												label: item?.project_name,
												value: item?.project_id
											}))
										}
										onChange={(e: any) => setFilterItem(e)}
										value={{ value: filterItem?.label }}
										isSearchable={true}
										isClearable
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="container-fluid bg-white py-3">
					<div className="row">
						<div className="col-12 custom-calander">
							<FullCalendar
								plugins={[dayGridPlugin]}
								initialView="dayGridMonth"
								weekends={true}
								events={
									filterItem?.value ? list?.items?.filter((item: any) => item?.project_id === filterItem?.value && (item.status_title === 'No Progress' || item.status_title === 'In Progress'))?.map((item: any) => ({

										title: JSON.stringify({
											id: item.id,
											title: item.title,
											assigned_to_fullname: item?.assigned_to_fullname,
											task_group_name: item?.task_group_name,
											project_id: item?.project_id,
											project_name: getProjectNameById(item?.project_id),
											due_ts: item?.due_ts

										}),
										start: changeDateFormate(item?.due_ts),
										end: changeDateFormate(item?.due_ts),
										color: '#e9ecef'
									}))
										: list?.items?.map((item: any) => (
											(item.status_title === 'No Progress' || item.status_title === 'In Progress') ?
												{
													title: JSON.stringify({
														id: item.id,
														title: item.title,
														assigned_to_fullname: item?.assigned_to_fullname,
														task_group_name: item?.task_group_name,
														project_id: item?.project_id,
														project_name: getProjectNameById(item?.project_id),
														due_ts: item?.due_ts
													}),
													start: changeDateFormate(item?.due_ts),
													end: changeDateFormate(item?.due_ts),
													color: '#e9ecef'
												} :
												{ title: JSON.stringify({ title: '' }) }
										))
								}
								eventContent={renderEventContent}
							/>

						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Calendar;