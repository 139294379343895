import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { Loading } from 'views/components'

const PublicLayout = () => {
	return (
		<>
			<Suspense fallback={<Loading />}>
				<main className="container-fluid">
					<div className="row align-items-center">
						<Outlet />
					</div>
				</main>
			</Suspense>
		</>
	)
}
export default PublicLayout