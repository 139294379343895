import React from 'react';

const Avatar = (props: any) => {
	return (
		<>
			<span className="rounded-circle bg-primary text-white d-flex align-items-center justify-content-center me-2" style={{ height: '40px', width: '40px' }}>{
				props?.data &&
				props?.data.split(' ')[0]?.charAt(0) +
				(props?.data.split(' ')[1]?.charAt(0) || '')
			}</span>
			<span>{props?.data}</span>
		</>
	);
};

export default Avatar;
