import { routes } from 'utils';
import { Home, Projects, Tasks } from 'views/pages';
import Calendar from 'views/pages/calendar';
import MonthlyTasks from 'views/pages/monthly-tasks';

const PrivateRoutes = [
	{ path: `${routes.dashboard}`, exact: true, name: 'home', component: Home },
	{ path: `${routes.tasks}`, exact: true, name: 'tasks', component: Tasks },
	{ path: `${routes.projects}`, exact: true, name: 'projects', component: Projects },
	{ path: `${routes.calendar}`, exact: true, name: 'calendar', component: Calendar },
	{ path: `${routes.monthlytasks}`, exact: true, name: 'monthly-tasks', component: MonthlyTasks }

]

export default PrivateRoutes;